const palette = {
  purple10: '#F4F3FA',
  purple20: '#E5E2F8',
  purple30: '#D2CBEB',
  purple40: '#ADA5C7',
  purple50: '#9186AF',
  purple60: '#746796',
  purple70: '#66588A',
  purple80: '#58487E',
  purple90: '#4A3972',
  purple100: '#3C2A65',

  blue10: '#F1F5FA',
  blue20: '#DFE8F4',
  blue30: '#C0D1E9',
  blue40: '#8BABD6',
  blue50: '#6690C9',
  blue60: '#4176BC',
  blue70: '#326BB7',
  blue80: '#285DA3',
  blue90: '#235291',
  blue100: '#1B4172',

  turquoise10: '#EAFAFC',
  turquoise20: '#C9F5F6',
  turquoise30: '#9CE9EA',
  turquoise40: '#68D7DA',
  turquoise50: '#50CDCF',
  turquoise60: '#38C2C4',
  turquoise70: '#1EA5A9',
  turquoise80: '#08838C',
  turquoise90: '#05717C',
  turquoise100: '#01515A',

  green10: '#ECF8F7',
  green20: '#D6EFEC',
  green30: '#ADDFD9',
  green40: '#84D0C6',
  green50: '#70C8BD',
  green60: '#54BCAF',
  green70: '#2FA596',
  green80: '#268478',
  green90: '#217469',
  green100: '#17534D',

  lime10: '#F8FDE5',
  lime20: '#F1FDC9',
  lime30: '#EBFCAC',
  lime40: '#DDFB74',
  lime50: '#D3F365',
  lime60: '#C9EC56',
  lime70: '#AACE32',
  lime80: '#94B524',
  lime90: '#738C12',
  lime100: '#4A5906',

  yellow10: '#FFFAE3',
  yellow20: '#FDF5CD',
  yellow30: '#FCEFB6',
  yellow40: '#FBE284',
  yellow50: '#F9DC66',
  yellow60: '#F8D648',
  yellow70: '#E5BE2D',
  yellow80: '#D3A818',
  yellow90: '#B48804',
  yellow100: '#704D00',

  brown10: '#F6F3EE',
  brown20: '#F0EAE1',
  brown30: '#E2D5C2',
  brown40: '#CCB695',
  brown50: '#C1A67E',
  brown60: '#B69667',
  brown70: '#9E825A',
  brown80: '#8B734F',
  brown90: '#735F42',
  brown100: '#4E402D',

  orange10: '#FFF1ED',
  orange20: '#FAE5DE',
  orange30: '#F5CABD',
  orange40: '#EFB09C',
  orange50: '#EA957B',
  orange60: '#E57B5A',
  orange70: '#CB6E50',
  orange80: '#B26046',
  orange90: '#A0563F',
  orange100: '#6B382A',

  red10: '#FBF1F2',
  red20: '#F9E9EC',
  red30: '#EFBDC5',
  red40: '#E9A7B2',
  red50: '#E3909F',
  red60: '#DD7A8B',
  red70: '#D0445C',
  red80: '#C7223E',
  red90: '#B31F38',
  red100: '#91182F',

  gray10: '#F6F9FC',
  gray20: '#ECF1F7',
  gray30: '#E2EAF1',
  gray40: '#D5DFE8',
  gray50: '#CCD5DF',
  gray60: '#B4C2CE',
  gray70: '#8796A4',
  gray80: '#5B6D7A',
  gray90: '#495862',
  gray100: '#364048',
}

export { palette }
