import { useEffect } from 'react'

const LAYERS_CONTAINER_ID = 'layers-container'

function useClickOutside(
  ref: React.RefObject<HTMLElement> | Array<React.RefObject<HTMLElement>>,
  callback: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Ignore clicks within the layers container – these are most
      // likely other popovers/modals/drawers opened within the context
      // of the current drawer or modal.
      if ((event.target as HTMLElement).closest(`#${LAYERS_CONTAINER_ID}`)) {
        return
      }

      const refs = Array.isArray(ref) ? ref : [ref]

      /* here we only call the callback (probably closing a drawer etc) if we clicked on a node that is not
       * contained in one of the refs passed. This is used in in drawers to prevent the underlying
       * drawer from closing when clicking child modals.
       */
      if (refs.every((ref) => ref.current && !ref.current.contains(event.target as Node))) {
        callback()
      }
    }

    document.addEventListener('mouseup', handleClickOutside)
    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [ref, callback])
}

export { LAYERS_CONTAINER_ID, useClickOutside }
