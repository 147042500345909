import { formatCurrencyWithThreshold } from '@capdesk/camo'
import { formatDateShort, formatDateLong, formatDateMonthYear, FORMAT_LOCALE } from '@capdesk/utils'
import currencyCodes from 'currency-codes'
import i18next, { type InterpolationOptions } from 'i18next'
import { type PropsWithChildren } from 'react'
import { I18nextProvider } from 'react-i18next'
import { useErrorHandler } from 'src/hooks/use-error-handler'
import { namespaces as ns } from 'src/i18n/en'
import { missingKeyHandler } from 'src/i18n/missing-key-handler'
import { resources } from 'src/i18n/resources'
import terminologyDefaults from 'src/i18n/terminology/defaults.json'
import { replaceTerms } from 'src/i18n/terminology/replace-terms'
import 'src/i18n/validator'
import { type TerminologySettings } from 'src/types/api/generated'
import { formatNumber } from 'src/utilities/format-number'
import { formatPercentage } from 'src/utilities/format-percentage'
import { isEmpty } from 'src/utilities/is-empty'

enum I18nFormat {
  LOWERCASE = 'LOWERCASE',
  LOWERCASE_EXCEPT_UPPERCASED = 'LOWERCASE_EXCEPT_UPPERCASED',
  DATE_SHORT = 'DATE_SHORT',
  DATE_LONG = 'DATE_LONG',
  MONTH_YEAR = 'MONTH_YEAR',
  NUMBER = 'NUMBER',
  TWO_FRACTION_DIGITS = 'TWO_FRACTION_DIGITS',
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
  CURRENCY_ISO = 'CURRENCY_ISO',
  CAPITALIZE_FIRST = 'CAPITALIZE_FIRST',
  PHONE_NUMBER = 'PHONE_NUMBER',
  LIST = 'LIST',
}

type TerminologyProviderProps = PropsWithChildren<
  XOR<{ companyId?: Id; terminologySettings?: TerminologySettings }, object>
>

/**
 * @param companyId used to uniquely identify the post processor function.
 * This must be provided if you supply custom terminology for a company.
 * @param terminologySettings TerminologySettings to use for this instance. If not supplied it will use the defaults.
 * @returns a new i18n provider with the current terminology settings applied in a post processor for each translation.
 */
const TerminologyProvider = ({
  terminologySettings = terminologyDefaults as TerminologySettings,
  companyId,
  children,
}: TerminologyProviderProps) => {
  const { handleClientError } = useErrorHandler()

  const postProcessorId = `${companyId ?? 'default'}-terminologyProcessor`

  const i18nInstance = i18next
    .use({
      type: 'postProcessor',
      name: postProcessorId,
      process: replaceTerms(terminologySettings, 'en'),
    })
    .createInstance(
      {
        postProcess: !isEmpty(terminologySettings) ? postProcessorId : undefined,
        resources,
        lng: 'en',
        ns,
        returnNull: false,
        saveMissing: true,
        missingKeyHandler,
        react: {
          transKeepBasicHtmlNodesFor: ['strong', 'i', 'p', 'ul', 'li', 'ol', 'span', 'div'],
        },
        interpolation: {
          escapeValue: false,
          format: (value, format, _, options: (InterpolationOptions & Record<string, any>) | undefined) => {
            switch (format) {
              case I18nFormat.LOWERCASE: {
                return value.toLowerCase()
              }
              // Examples:
              //   * RSU Agreement => RSU agreement
              //   * Share Grant Agreement => share grant agreement
              case I18nFormat.LOWERCASE_EXCEPT_UPPERCASED: {
                const { separator = ' ' } = options ?? {}

                return value
                  .split(separator)
                  .map((part: string) => (part === part.toUpperCase() ? part : part.toLowerCase()))
                  .join(separator)
              }
              case I18nFormat.DATE_SHORT: {
                return formatDateShort(value)
              }
              case I18nFormat.DATE_LONG: {
                return formatDateLong(value)
              }
              case I18nFormat.MONTH_YEAR: {
                return formatDateMonthYear(value)
              }
              case I18nFormat.NUMBER: {
                const { notation = 'standard' } = options ?? {}
                if (Number.isNaN(value)) {
                  return null
                }
                return formatNumber(value, { notation })
              }
              case I18nFormat.TWO_FRACTION_DIGITS: {
                return new Intl.NumberFormat(FORMAT_LOCALE, {
                  maximumFractionDigits: 2,
                }).format(value)
              }
              case I18nFormat.PERCENTAGE: {
                const { digits, precision } = options ?? {}

                return formatPercentage(value, {
                  minimumFractionDigits: digits,
                  maximumFractionDigits: precision ?? digits,
                })
              }
              case I18nFormat.CURRENCY: {
                const { ...formatOptions } = options ?? {}

                return formatCurrencyWithThreshold(value, formatOptions)
              }
              case I18nFormat.CURRENCY_ISO: {
                const { currency, precision, formatParams, interpolationkey } = options ?? {}
                const currencyToUse = formatParams?.[interpolationkey]?.currency ?? currency
                const digits = currencyCodes.code(currencyToUse)?.digits
                return new Intl.NumberFormat(FORMAT_LOCALE, {
                  style: 'currency',
                  currency: currencyToUse,
                  currencyDisplay: 'code',
                  minimumFractionDigits: digits,
                  maximumFractionDigits: precision ?? digits,
                }).format(value)
              }
              case I18nFormat.CAPITALIZE_FIRST: {
                return String(value.charAt(0).toUpperCase()) + String(value.slice(1))
              }
              case I18nFormat.PHONE_NUMBER: {
                const { dialCode, phone } = value
                return `+${dialCode} ${phone}`
              }
              case I18nFormat.LIST: {
                const formatter = new Intl.ListFormat('en', {
                  style: 'long',
                  type: 'conjunction',
                })

                return formatter.format(value)
              }
              default: {
                return value
              }
            }
          },
        },
      },
      // Without this second param, i18next throws an error
      (err) => {
        if (err) {
          handleClientError({ error: err })
        }
      }
    )
  return <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
}

export { TerminologyProvider }
export type { TerminologyProviderProps, TerminologySettings }
